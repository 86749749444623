<template>
  <section id="inss">
    <div class="container jumbotron">
      <ImportManadForm
        @showUploadComponent="showUploadComponent = $event"
        @dataImportManadForm="dataImportManad = $event"
      />
      <UploadComponentInss
        v-show="showUploadComponent"
        :paramsImportFiles="dataImportManad"
      />
    </div>
  </section>
</template>

<script>

import ImportManadForm from "@/Components/Teses/Inss/ImportManadForm.vue";
import UploadComponentInss from "@/Components/UploadComponent/UploadComponentInss.vue";
import UploadInssService from "@/services/uploadInss.service.js";

export default {
  name: "inss",

  components: {
    ImportManadForm,
    UploadComponentInss,
  },

  data() {
    return {
      showUploadComponent: true,
      dataImportManad: {},
      loadingSpinner: false,
      aliquotas: {
        AliquotaInss: "20",
        AliquotaS5: "5,8",
        AliquotaRAT: "1,5",
      },
      tables: false,
      niveis: { nivel1: [], nivel2: [], nivel3: [], outrasEntidades: [] },

      sortBy: {
        nivel1: "codigoRubrica",
        nivel2: "codigoRubrica",
        nivel3: "codigoRubrica",
      },
      sortDesc: { nivel1: false, nivel2: false, nivel3: false },
      fields: [
        {
          key: "codigoRubrica",
          label: "Código rúbrica",
          sortable: true,
        },
        { key: "descricao", label: "Descrição da verba", sortable: true },
        {
          key: "valorInss",
          label: "Valor INSS",
          sortable: true,
        },
        {
          key: "valorRat",
          label: "Valor RAT",
          sortable: true,
        },
        {
          key: "valorS5",
          label: "Valor S5",
          sortable: true,
        },
        {
          key: "valorTotal",
          label: "Valor total",
          sortable: true,
        },
        {
          key: "nivel",
          label: "Ações",
          sortable: false,
        },
      ],
      uploaded: null,
      nivel1Total: [],
      nivel2Total: [],
      nivel3Total: [],
      nCodOp: "",
      filesSuccessAdded: []
    };
  },

  mounted() {
    this.nCodOp = this.$route.params.nCodOp;
  },

  methods: {
    submit() {
      this.tables = true;
      this.loadingTables = true;

      let aliquotas = {
        NCodOp: this.$route.params.nCodOp,
        AliquotaInss: this.aliquotas.AliquotaInss,
        AliquotaS5: this.aliquotas.AliquotaS5,
        AliquotaRAT: this.aliquotas.AliquotaRAT,
      };

      UploadInssService.processarCalcInss(aliquotas).then((response) => {
        response.data.forEach((element) => {
          switch (element.nivel) {
            case 1:
              this.niveis.nivel1.push({
                codigoRubrica: element.codigoRubrica,
                descricao: element.descricao,
                valorInss: element.valorInss.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                valorRat: element.valorRat.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                valorS5: element.valorS5.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                valorTotal: element.valorTotal.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                nivel: element.nivel,
              });
              break;
            case 2:
              this.niveis.nivel2.push({
                codigoRubrica: element.codigoRubrica,
                descricao: element.descricao,
                valorInss: element.valorInss.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                valorRat: element.valorRat.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                valorS5: element.valorS5.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                valorTotal: element.valorTotal.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                nivel: element.nivel,
              });
              break;
            case 3:
              this.niveis.nivel3.push({
                codigoRubrica: element.codigoRubrica,
                descricao: element.descricao,
                valorInss: element.valorInss.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                valorRat: element.valorRat.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                valorS5: element.valorS5.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                valorTotal: element.valorTotal.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }),
                nivel: element.nivel,
              });
              break;
            case 4:
              this.niveis.outrasEntidades.push({
                descricao: element.descricao,
                valorTotal: element.valorTotal,
              });
              break;
          }
        });
        this.loadingTables = false;
      });
    },

    listarManad(){
      this.$router.push(`/listamanads`);
    },

    changeNivel(obj, nivel) {
      switch (obj.nivel) {
        case 1:
          this.niveis.nivel1.splice(this.niveis.nivel1.indexOf(obj), 1);
          break;
        case 2:
          this.niveis.nivel2.splice(this.niveis.nivel2.indexOf(obj), 1);
          break;
        case 3:
          this.niveis.nivel3.splice(this.niveis.nivel3.indexOf(obj), 1);
          break;
      }
      switch (nivel) {
        case 1:
          obj.nivel = nivel;
          this.niveis.nivel1.push(obj);
          break;
        case 2:
          obj.nivel = nivel;
          this.niveis.nivel2.push(obj);
          break;
        case 3:
          obj.nivel = nivel;
          this.niveis.nivel3.push(obj);
          break;
      }
    },
    DeletefromNivel(obj) {
      UploadInssService.processarDeleteInss(
        this.$route.params.nCodOp,
        obj.codigoRubrica,
        this.$route.params.cnpj
      ).then((reponse) => {
        if (reponse.data) {
          switch (obj.nivel) {
            case 1:
              this.niveis.nivel1.splice(this.niveis.nivel1.indexOf(obj), 1);
              break;
            case 2:
              this.niveis.nivel2.splice(this.niveis.nivel2.indexOf(obj), 1);
              break;
            case 3:
              this.niveis.nivel3.splice(this.niveis.nivel3.indexOf(obj), 1);
              break;
          }
        }
      });
    },
    saveChanges() {
      this.$bvModal
        .msgBoxConfirm("Salvar alterações?", {
          okVariant: "outline-success",
          okTitle: "Salvar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-danger",
        })
        .then((value) => {
          if (value === true) {
            alert("ok");
          }
        });
    },
  },
};
</script>
<style scoped>
/* ids */
#aliquotas {
  border: 0.2em solid rgba(61, 169, 56, 0.8);
}

/* classes */
.aliquotas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 10%;
}

.btncalculo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
}

.values {
  border: none !important;
}
</style>
